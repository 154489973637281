body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.result {
  margin-top: 1rem;
}

.interview-conditions-list li {
  margin-bottom: 0.5rem;
}

.interview-conditions-item {
  display: flex;
  justify-content: space-between;
}

.toppings-list li:last-child {
  border-top: 1px solid #ccc;
  margin-top: 1rem;
  padding-top: 1rem;
}

.interview-conditions-list label {
  margin-left: 0.5rem;
}

.required-field::after {
    content: "*";
    color: red;
}
